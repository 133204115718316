import {
  GET_PERMISSIONED_ACCOUNT_PERMISSIONS_REQUEST ,
  GET_PERMISSIONED_ACCOUNT_PERMISSIONS_FAILURE,
  GET_PERMISSIONED_ACCOUNT_PERMISSIONS_SUCCESS,

  GET_OWNED_ACCOUNT_PERMISSIONS_REQUEST ,
  GET_OWNED_ACCOUNT_PERMISSIONS_FAILURE,
  GET_OWNED_ACCOUNT_PERMISSIONS_SUCCESS,

  DELETE_ACCOUNT_PERMISSIONS_REQUEST,
  DELETE_ACCOUNT_PERMISSIONS_FAILURE,
  DELETE_ACCOUNT_PERMISSIONS_SUCCESS,

  UPDATE_SINGLE_ACCOUNT_PERMISSIONS_REQUEST,
  UPDATE_SINGLE_ACCOUNT_PERMISSIONS_FAILURE,
  UPDATE_SINGLE_ACCOUNT_PERMISSIONS_SUCCESS,

  POST_ACCOUNT_PERMISSIONS_REQUEST,
  POST_ACCOUNT_PERMISSIONS_SUCCESS,
  POST_ACCOUNT_PERMISSIONS_FAILURE,

  ADD_GROWER_REQUEST,
  ADD_GROWER_SUCCESS,
  ADD_GROWER_FAILURE,

  DELETE_GROWER_REQUEST,
  DELETE_GROWER_SUCCESS,
  DELETE_GROWER_FAILURE
} from './permissionsActions'
import { createSelector } from 'reselect'

const initialState = {
  permissionedAccounts: {
    pending: false,
    hasError: false,
    error: null,
    allIds: [],
    byId: {},
    byAccount: {}
  },
  ownedAccounts: {
    pending: false,
    hasError: false,
    error: null,
    byId: null,
    allIds: {}
  }
}

export const permissionsReducer = (state = { ...initialState }, action) => {
  switch(action.type) {

    ////////////////////////
    // Get Handlers
    ////////////////////////
    case GET_OWNED_ACCOUNT_PERMISSIONS_REQUEST: {
      return {
        ...state,
        ownedAccounts: {
          ...state.ownedAccounts,
          pending: action.isFetching
        }
      }
    }
    case GET_OWNED_ACCOUNT_PERMISSIONS_SUCCESS: {
      const byId = {}
      const allIds = []
      action.payload.forEach(entity => {
        byId[entity.id] = entity
        allIds.push(entity.id)
      })
      return {
        ...state,
        ownedAccounts: {
          ...state.ownedAccounts,
          byId,
          allIds
        }
      }
    }
    case  GET_OWNED_ACCOUNT_PERMISSIONS_FAILURE: {
      return {
        ...state,
        ownedAccounts: {
          pending: false,
          hasError: true,
          error: action.error
        }
      }
    }

    case GET_PERMISSIONED_ACCOUNT_PERMISSIONS_REQUEST: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: action.isFetching
        }
      }
    }
    case GET_PERMISSIONED_ACCOUNT_PERMISSIONS_SUCCESS: {
      const byId = {}
      const byAccount = {}
      const allIds = []
      action.payload.forEach(permission => {
        byId[permission.id] = permission
        allIds.push(permission.id)
        byAccount[permission.owning_account_id] = permission
      })
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          byId,
          byAccount,
          allIds,
          pending: false,
          hasError: false,
        }
      }
    }
    case  GET_PERMISSIONED_ACCOUNT_PERMISSIONS_FAILURE: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: false,
          hasError: true,
          error: action.error
        }
      }
    }

    case DELETE_ACCOUNT_PERMISSIONS_REQUEST: {
      const permissionType = state[action.permissionType]
      return {
        ...state,
        [action.permissionType]: {
          ...permissionType,
          pending: action.isFetching
        }
      }
    }
    case DELETE_ACCOUNT_PERMISSIONS_SUCCESS: {
      const { permissionId } = action
      const permissionType = state[action.permissionType]
      const newById = {...permissionType.byId }
      delete newById[permissionId]
      const newAllIds = permissionType.allIds.filter((id) => id !== permissionId)

      state[action.permissionType] = {
        ...permissionType,
        byId: {...newById},
        allIds: [...newAllIds],
        pending: false,
        hasError: false
      }
      return {
        ...state,
      }
    }
    case  DELETE_ACCOUNT_PERMISSIONS_FAILURE: {
      const permissionType = state[action.permissionType]
      return {
        ...state,
        [action.permissionType]: {
          ...permissionType,
          pending: false,
          hasError: true,
          error: action.error
        }
      }
    }

    case POST_ACCOUNT_PERMISSIONS_REQUEST: {
      const { permissionType } = action
      return {
        ...state,
        [permissionType]: {
          ...state.permissionType,
          pending: false,
          hasError: false,
        }
      }
    }

    case POST_ACCOUNT_PERMISSIONS_SUCCESS: {
      const { payload, permissionType } = action
      const byId = {...state[permissionType].byId }
      const allIds = [...state[permissionType].allIds]
      byId[payload.id] = payload
      allIds.push(payload.id)
      return {
        ...state,
        [permissionType]: {
          ...state[permissionType],
          byId,
          allIds,
          pending: false,
          hasError: false,
        }
      }
    }

    case POST_ACCOUNT_PERMISSIONS_FAILURE: {
      const { err, permissionType } = action
      return {
        ...state,
        [permissionType]: {
          ...state[permissionType],
          pending: false,
          hasError: true,
          error: err
        }
      }
    }


    case UPDATE_SINGLE_ACCOUNT_PERMISSIONS_REQUEST: {
      const { permissionType } = action
      return {
        ...state,
        [permissionType]: {
          ...state[permissionType],
          pending: true,
          hasError: false
        }
      }
    }
    case UPDATE_SINGLE_ACCOUNT_PERMISSIONS_SUCCESS: {
      const { permissionType, data } = action
      const stateOfType= state[permissionType]
      //updating keys from payload as user details will not change and are not provided with patch payload
      // the patch payload can be revisited, but are dependent on requester identity.
      const newPermissionDetails = {...stateOfType.byId[data.id]}
      for (let key in data){
        newPermissionDetails[key] = data[key]
      }
      const newById = { ...stateOfType.byId, [data.id]: newPermissionDetails }
      return {
        ...state,
        [permissionType]: {
          ...stateOfType,
          byId: newById,
          pending: false,
          hasError: false
        }
      }
    }
    case  UPDATE_SINGLE_ACCOUNT_PERMISSIONS_FAILURE: {
      const {permissionType, error } = action
      return {
        ...state,
        [permissionType]: {
          ...state[permissionType],
          pending: false,
          hasError: true,
          error: error
        }
      }
    }

    case ADD_GROWER_REQUEST: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: true
        }
      }
    }
    case ADD_GROWER_SUCCESS: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          byId: {
            ...state.permissionedAccounts.byId,
            [action.permission.id]: action.permission
          },
          byAccount: {
            ...state.permissionedAccounts.byAccount,
            [action.permission.owning_account_id]: action.permission
          },
          allIds: [...state.permissionedAccounts.allIds, action.permission.id],
          pending: false,
          hasError: false,
        }
      }
    }
    case ADD_GROWER_FAILURE: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: false,
          hasError: true,
          error: action.error
        }
      }
    }

    case DELETE_GROWER_REQUEST: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: true
        }
      }
    }

    case DELETE_GROWER_SUCCESS: {
      let byId = {...state.permissionedAccounts.byId }
      delete byId[action.permissionId]
      let newByAcct = {}
      Object.entries(state.permissionedAccounts.byAccount).forEach((acctId, permission) => {
        if (permission.id !== action.permissionId) {
          newByAcct[acctId] = permission
        }
      })
      let allIds = state.permissionedAccounts.allIds.filter(id => id !== action.permissionId)
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          byId,
          allIds,
          byAccount: newByAcct
        }
      }
    }

    case DELETE_GROWER_FAILURE: {
      return {
        ...state,
        permissionedAccounts: {
          ...state.permissionedAccounts,
          pending: false,
          hasError: true,
          error: action.error
        }
      }
    }

    default: {
      return state
    }
  }
}



const getPermissionsById = state=>state.entities.permissions.permissionedAccounts.byId

const swapPermissionKey = byId => {
  return Object.fromEntries(Object.values(byId).map(acct=>[acct.owning_account_id, acct]))
}
export const permissionsByAccount = createSelector(
  [getPermissionsById],
  swapPermissionKey
)

const selectedAccountId = state=>state.auth.selectedAccounts?.[0]
const loggedInUser = state=>state.auth.user

export const getSelectedAccountPermission = createSelector(
  [permissionsByAccount, selectedAccountId],
  (permissions, id)=>permissions[id]
)

//get viewed user details regardless of if logged in user or permissioned user. Default to logged in user
export const getSelectedAccountUserDetails = createSelector(
  [getSelectedAccountPermission, loggedInUser],
  (permission, user)=>permission ? permission.user_details : user
)

export const getSelectedAccountPermissionUserName = createSelector(
  [getSelectedAccountPermission, loggedInUser],
  (permission, user)=>permission
    ? permission.user_details
      ? `${permission.user_details.first_name} ${permission.user_details.last_name}`
      : permission.account_name
    : `${user.first_name} ${user.last_name}`
)

export const canEditAccount = createSelector(
  [getSelectedAccountPermission],
  (permission)=>permission ? permission.access_type === 'edit' : true
)
