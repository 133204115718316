import React, { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '../svgs/Info'

import styled from 'styled-components'

const StyledInnerToolTip = styled.div`
  width: unset;
  position: absolute;
  bottom: 24px;

  &.bottom{
    top: 24px;
    bottom: unset;
  }

  &.left {
    top: 24px;
    bottom: 24px;
    right: calc(100% - 120px);
  }

  & .box {
    padding: 20px 20px 20px 20px;
    color: white;
    border-radius: 4px;
    background: ${props => props.theme.colors.primary};
    width: 300px;
    text-align: center;
    position: relative;
    right: calc(50% - 8px);
    font-size: 14px;
    z-index: 2;
  }

  & .caret {
    display: ${props => props.hideCaret ? 'none' : 'unset'};
    -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    height: 10px;
    width: 20px;
    margin: -1px auto;
    background: ${props => props.innerTipStyle ? props.innerTipStyle.background : props.theme.colors.primary};
    position: relative;
    right: calc(50% - 8px);

    &.bottom {
      transform: rotate(180deg);
    }
  }
`

const InnerToolTip = (props) => (
  <StyledInnerToolTip className={props.direction} innerTipStyle={props.innerTipStyle} hideCaret={props.hideCaret}>
    {props.direction === 'bottom' && <div className='caret bottom'> </div>}
    <div className="box" style={{ ...props.innerTipStyle }}>
      {props.children}
    </div>
    {!props.direction === 'bottom' && <div className='caret'> </div>}
  </StyledInnerToolTip>
)

InnerToolTip.propTypes = {
  innerTipStyle: PropTypes.object,
  direction: PropTypes.string, //one of 'top','bottom', 'left'
  hideCaret: PropTypes.bool
}


/**
 * ToolTip is a multi-purpose component that can be inserted anywhere more help is needed
 * On the page, the component looks like an icon (an 'info' icon shown if none is passed in)
 * On hover/ click a teal box appears with the react components children rendered inside.
 * @param {object} props with keys of children and optionally an SVGIcon to display
 */
const ToolTip = (props) => {
  const { children, hideCaret, SvgIcon = InfoIcon, overrideStyle = {}, innerTipStyle = {} } = props
  const [openState, setOpenState] = useState(false)
  const [clickState, setClickState] = useState(false)
  const tipNode = useRef()

  const handleClick = useCallback((e) => {
    //if not clicking on element, close tip
    if (!tipNode.current.contains(e.target)) {
      e.stopPropagation()
      setClickState(false)
      setOpenState(false)
    } else {
      e.stopPropagation()
      setOpenState(!clickState)
      setClickState(!clickState)
    }
  }, [clickState])

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick])

  const handleMouseLeave = () => {
    if (clickState) {
      return
    }
    setOpenState(false)
  }

  const handleMouseEnter = () => {
    setOpenState(true)
  }

  return (
    <span
      ref={tipNode}
      style={{
        float: 'right',
        width: '14px',
        height: '14px',
        position: 'relative',

        ...overrideStyle
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SvgIcon
        width='130%'

      />
      {
        openState &&
        <InnerToolTip direction={props.direction} innerTipStyle={innerTipStyle} hideCaret={hideCaret}>
          {children}
        </InnerToolTip>
      }
    </span>
  )

}

ToolTip.defaultProps = {
  hideCaret: false
}

ToolTip.propTypes = {
  overrideStyle: PropTypes.object,
  innerTipStyle: PropTypes.object,
  SvgIcon: PropTypes.func,
  direction: PropTypes.string, //one of 'top','bottom', 'left'
  hideCaret: PropTypes.bool
}

export default ToolTip
