import React from 'react'
import PropTypes from 'prop-types'
import { theme } from '../utils/theme'

const SVG = ({
  style = {},
  fill = theme.colors.primary,
  width = '12px',
  className = '',
  viewBox = '0 0 24 24',
  ...other
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox || `0 0 24 24`}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...other}
  >
    <path xmlns="http://www.w3.org/2000/svg" d="M0 0h24v24H0z" fill={'none'} />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
    />
  </svg>
)

SVG.propTypes = {
  style: PropTypes.string,
  fill: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string,
}

export default SVG
