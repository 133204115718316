import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const StyledCard = styled.div`
  border-radius: 6px;
  background-color: #ffffff;
  padding: 1em;

  margin-bottom: 1.5em;
  display: flex;
  flex-direction: column;
  // create pseudo element so that box shadows don't overlap
  :before{
    box-shadow: 0px 20px 30px rgba(28,81,112,0.1);
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  @media screen and (min-width: 992px){
    height: 100%;
    margin-bottom: 0;
  }

`

export const Card = ({ children, title, overrideStyle, className }) => (
  <StyledCard style={overrideStyle} className={className}>
    {title && <h5 style={{ 'fontWeight': 'bold' }}>
      {title}
    </h5>
    }
    {children}
  </StyledCard>
)

Card.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  overrideStyle: PropTypes.object,
  className: PropTypes.string
}
