import { MODAL_HIDE, MODAL_SHOW, MODAL_SHOW_TUBEID, MODAL_HIDE_TUBEID } from './modalActions'

const initialState = {
  modalState: false,
  modalStateTubeId: false,
  modalType: ''
}

export default function modalReducer(state = initialState, action) {
  switch (action.type) {
    case MODAL_HIDE: {
      return { ...state, modalState: false, modalType: '' }
    }
    case MODAL_SHOW: {
      return { ...state, modalState: true, modalType: action.modalType }
    }
    case MODAL_HIDE_TUBEID: {
      return { ...state, modalStateTubeId: false, modalState: false }
    }
    case MODAL_SHOW_TUBEID: {
      return { ...state, modalStateTubeId: true }
    }
    default: return state
  }
}
