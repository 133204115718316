
import { createTheme } from '@material-ui/core/styles'
const theme = {
  colors: {
    primary: '#2EC4B2',
    red: '#F44336',
    transparent: 'transparent',
    white: '#FFFFFF',
    black: '#000',
    traceTeal: '#2EC4B2',
    lightTeal: '#6EF7E4',
    tealDark: '#008575',
    deepBlue: '#1F4266',
    deepLight: '#4F6D94',
    deepDark: '#001C3B',
    sharpBlue: '#63ADF2',
    sharpLight: '#99DFFF',
    sharpDark: '#227EBF',
    navBarBlue: '#2A3B54',
    nearBlack: '#393D3F',
    soilGray: '#767573',
    janeGray: '#D9D9D9',
    disabledGray: '#9F9F9F',
    dividerGray: '#E0E0E0',
    tableGray: '#F5F5F5',
    warning: '#E42535',
    warningLight: '#FF8A91',
    attention: '#E49825',
    attentionLight: '#FFC958',
    positive: '#16BE78',
    positiveLight: '#60F2A7',
    gray: '#767676',
    linkWater: '#DEECF5',
    region_pink: '#D28DEC',
    region_green: '#52FF97',
    region_teal: '#78DFFF',
    map_green_dark: '#94B657',
    map_green: '#A8CE5C',
    map_yellow: '#FCB212',
    map_yellow_dark: '#E49825',
    map_orange: '#F9B214',
    map_orangeRed: '#FA921D',
    map_red: '#DC4D4B',
    map_red_dark: '#BF4F4B',
    accountNotification: '#EDF5FA'
  },
  font: {
    size: {
      big: '18px',
      normal: '14px',
      small: '12px',
    },
    weight: {
      normal: 'normal',
      bold: 'bold',
      bolder: 'bolder',
      lighter: 'lighter'
    },
  },
  heading: {
    desktop: {
      h1: '32px',
      h2: '24px',
      h3: '18px',
      h4: '18px'
    },
    mobile: {
      h1: '24px',
      h2: '24px',
      h3: '18px',
      h4: '18px'
    },
  },
}

const headerFontFamily = [
  'Montserrat',
  'Lato',
  'Ariel'
].join(',')

const standardFontFamily = [
  'Lato',
  'Montserrat',
  'Ariel'
].join(',')

const muiTheme = createTheme({
  ...theme,
  typography: {
    fontFamily: standardFontFamily,
    h1: {
      fontFamily: headerFontFamily
    },
    h2: {
      fontFamily: headerFontFamily
    },
    h3: {
      fontFamily: headerFontFamily
    }
  },
  palette: {
    ...theme.colors,
    primary: { main: theme.colors.primary },
    secondary: { main: theme.colors.sharpDark },
    warning: {
      main: theme.colors.warning,
      light: theme.colors.warningLight
    }
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 20px 30px rgba(28,81,112,0.1)',
      },
    }
  }
})

export { theme, muiTheme }
