import { createSelector } from 'reselect'

import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAILURE
} from './products-actions'
import { groupBy } from '../../common/utils/sortHelpers'
import { getProductDescription } from '../../components/Orders/helpers'
import { getAllByKey } from './indicator-reducer'
import { loggedInUser } from '../../components/auth/auth-reducer'

const initialState = {
  pending: false,
  serverError: null,
  byId: {},
  allIds: []
}

// constants
export const pscoreAnalysisProductId = 1

export const productsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case PRODUCTS_REQUEST: return { ...state, pending: true, serverError: null }
    case PRODUCTS_FAILURE: return { ...state, pending: false, serverError: action.error }
    case PRODUCTS_SUCCESS: {
      return {
        ...state,
        pending: false,
        serverError: null,
        byId: { ...state.byId, ...groupBy(action.products, el => el.id, false) },
        allIds: [ ...state.allIds, action.products.map(p => p.id) ]
      }
    }
    default: return state
  }
}

const products = state => state.entities.products

// Selector for product descriptions
export const getProductsWithDescriptions = createSelector(
  [products, getAllByKey, loggedInUser],
  (products, allByKey, loggedInUser) => {
    const productsWithDescriptions = (Object.values(products.byId)).map(p => ({ ...p, description: getProductDescription(p.indicator_keys, allByKey) }))
    return {
      // Note: byDisplayName is filtered down to user products
      byDisplayName: groupBy(productsWithDescriptions.filter(p => loggedInUser.product_ids.includes(p.id)), el => el.display_name, false),
      byId: groupBy(productsWithDescriptions, el => el.id, false),
      defaultProduct: productsWithDescriptions.find(p => p.id === loggedInUser.default_product_id)
    }
  }
)
