import React from 'react'
import { toTitleCase, yearFirstSkewerCaseToUS } from '../../common/utils/stringAndDateHelpers'
import ToolTip from '../../common/components/ToolTip'
import Select from 'react-select'
import {
  StyledMultiDropDown,
  StyledInputLabel
} from './AnalyticsStyles'
import { numericStringComparison, skewerDateSort } from '../../common/utils/sortHelpers'
import propTypes from 'prop-types'

//helper to convert an array of string options to a form usable by the react-select Select Component
//transform function can be given to translate array values to a label value
export const arrayToOptions = (array, transformLabel = (val) => val, transformValue = (val) => val) => {
  return array.map((value, idx) => ({ value: transformValue(value, idx), label: transformLabel(value, idx) }))
}

/**
 * Helper React component for a single dropdown object and label in bootstrap col
 * @param {Object} props contains filterName(name for list),
 * options(array of options with form {value, label}),
 * onChange(change handler that receives an option object)
 */
export const MultiDropDownColumn = ({
  filterName,
  options,
  onChange,
  defaults,
  classOverride = 'col-md-6 col-sm-6 col-12',
  styleOverride = {},
  toolTipContent = '',
  ...rest
}) => (
  <StyledMultiDropDown id={filterName} className={classOverride} style={styleOverride}>
    <StyledInputLabel>
      {toTitleCase(filterName)}
      {toolTipContent &&
        <ToolTip>
          {toolTipContent}
        </ToolTip>
      }
    </StyledInputLabel>
    <div style={{ fontSize: 16 }}>
      <Select
        styles={{
          menu: provided => ({ ...provided, zIndex: 9999 })
        }}
        onChange={onChange}
        options={options}
        placeholder={`Select ${toTitleCase(filterName)}`}
        value={defaults.map(defaultVal =>
          options.find(el => el.value === defaultVal)
        )}
        isMulti
        {...rest}
      />
    </div>
  </StyledMultiDropDown>
)

MultiDropDownColumn.propTypes = {
  /** Name for field label */
  filterName: propTypes.string,
  /** Set if values to pass into Select */
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string,
      label: propTypes.string,
    })
  ),
  /** Optional content of a tooltip element if wanted  */
  toolTipContent: propTypes.string,
  /** On change function */
  onChange: propTypes.func,
  /** Values to set in Select component */
  defaults: propTypes.array,
  /** class override for surrounding div element.
   * default applies bootstrap classes: 'col-md-6 col-sm-6 col-12'
   */
  classOverride: propTypes.string,

  /**
   * Allows additional styling of surrounding div element
   */
  styleOverride: propTypes.object
}

/**
 * Validate that a sample matches a set of filters
 *
 * @param {object} sample sample object to validate
 * @param {object} sampleFilters set of filters to validate against.
 * Supported filters are those in the analytics reducer sampleFilter object
 * @param {boolean} [filterById = true] If the filter function should check against the 'id' filter
 *
 * @returns {boolean} true if sample passes all filters, false otherwise.
 */
export const sampleMatchesFilters = (sample, sampleFilters, filterById = true) => {
  if (!(sample.processed_date || sample.chem_processed_date || sample.processing_final_failure_date)) {
    return false
  }
  let passAllFilters = true
  for (let key in sampleFilters) {
    //handle case of undefined key value, empty string and empty array
    //id is the exception as all values need to be selected to show in graph
    if (!sampleFilters[key] || sampleFilters[key].length === 0) {
      continue
    }
    //check fields/farms
    if (key === 'field' || key === 'farm' || (filterById && key === 'id')) {
      if (!sampleFilters[key].includes(sample[key])) {
        return false
      }
      //check dates
    } else if (key === 'samplingDateStart' || key === 'samplingDateEnd') {
      let sampleDate = new Date(...(sample.sampling_date.split('-')))
      let compareDate = new Date(...(sampleFilters[key].split('-')))
      if (key === 'samplingDateStart' && sampleDate < compareDate) {
        return false

      } else if (key === 'samplingDateEnd' && sampleDate > compareDate) {
        return false
      }
      //check planted crop
    } else if (key === 'plantedCrop') {
      if (
        !sampleFilters[key].includes(sample.previous_crop) &&
        !sampleFilters[key].includes(sample.current_crop) &&
        !sampleFilters[key].includes(sampleFilters.includeNext && sample.next_crop)
      ) {
        return false
      }
      //check treatments
    } else if (key === 'sampleTags') {
      let passTags = false
      sampleFilters[key].forEach(tagName => {
        if (sample['sample_tags'] && sample['sample_tags'].includes(tagName)) {
          passTags = true
        }
      })
      passAllFilters = passTags
    }
  }
  return passAllFilters
}



/**
 * Helper function parses an array of sample objects to the form for display in graph
 * [{
 * grouping,
 * Average value,
 * Number of Samples in grouping
 * }]
 *
 * @param {Array} samples
 * @param {String} grouping
 * @param {String} crop
 * @param {String} indicator
 */
export const parseDataToGraphShape = (samples, grouping, indicator) => {
  //Creates an object with key values: {grouping: [{id, value}]}

  const samplesByGrouping = {}

  // TODO: determing pending status based on ordered products
  samples.forEach(sample => {
    let indicatorValue, pending
    if (!(indicator.key in sample.results)) {
      indicatorValue = undefined
      pending = true
      if (['shi', 'pathogen'].includes(indicator.indicator_type)) {
        if (!sample.include_bio) {
          pending = false
        }
      } else {
        // if not bio it is chemistry
        if (!sample.include_chem) {
          pending = false
        }
      }
    } else {
      indicatorValue = sample.results[indicator.key]
    }
    let sampleObj = { id: sample.id, value: indicatorValue, field: sample.field, farm: sample.farm, grower: sample.grower }
    // NOTE: this grouping is only accessible through the cumulative print report
    if (grouping === 'farm-field') {
      sample['farm-field'] = `${sample.farm}-${sample.field}`
    }
    if (grouping === 'id') {
      const { field, farm, sample_tags, latitude, longitude, sampling_date } = sample
      sampleObj = {
        field,
        farm,
        sample_tags,
        latitude,
        longitude,
        sampling_date,
        nextCrop: sample.next_crop,
        prevCrop: sample.previous_crop,
        currentCrop: sample.current_crop,
        pending: pending,
        ...sampleObj
      }
    }
    //sample tags are unique case
    if (grouping === 'sampleTags') {
      if (!sample.sample_tags || sample.sample_tags.length === 0) {
        samplesByGrouping['undefined'] = samplesByGrouping['undefined'] ? samplesByGrouping['undefined'] : []
        samplesByGrouping['undefined'].push(sampleObj)
      } else {
        sample.sample_tags && sample.sample_tags.forEach(tag => {
          samplesByGrouping[tag] = samplesByGrouping[tag] ? samplesByGrouping[tag] : []
          samplesByGrouping[tag].push(sampleObj)
        })
      }
    } else {
      if (!(sample[grouping] in samplesByGrouping)) {
        samplesByGrouping[sample[grouping]] = [sampleObj]
      } else {
        samplesByGrouping[sample[grouping]].push(sampleObj)
      }
    }
  })
  let groupingValuesTuples = Object.entries(samplesByGrouping)

  //if the grouping is by id, map the array entries to sample name
  if (grouping === 'id') {
    groupingValuesTuples = groupingValuesTuples.map(([id, values]) => {
      const result = samples.find(sample => {
        return sample.id === id
      })
      return [result.sample_name, values]
    })
  }
  //go through the sorted array and average the values
  let ret = groupingValuesTuples.map(([groupingName, values]) => {
    //dates to US format
    if (grouping === 'sampling_date') {
      groupingName = yearFirstSkewerCaseToUS(groupingName)
    }
    //null values will be excluded from averages
    let filterNullValues = values.filter(val => val.value !== null && val.value !== undefined).map(val => val.value)
    let average = (filterNullValues.reduce((memo, val) => (memo + val), 0) / filterNullValues.length)
    const resultObj = {
      'grouping': groupingName === 'undefined' ? `No ${grouping}` : groupingName,
      'Value': filterNullValues.length ? average : null,
      'sampleIds': values.map((val) => val.id)
    }
    if (grouping === 'id') {
      return (
        {
          field: values[0].field,
          farm: values[0].farm,
          grower: values[0].grower,
          latitude: values[0].latitude,
          longitude: values[0].longitude,
          'sampling_date': yearFirstSkewerCaseToUS(values[0].sampling_date),
          'next crop': values[0].nextCrop,
          'prev crop': values[0].prevCrop,
          'current crop': values[0].currentCrop,
          pending: values[0].pending,
          ...resultObj
        }
      )
    }
    if (grouping === 'farm-field') {
      // for cumulative print report smart thresholds by field
      return {
        ...resultObj,
        grower: values.length ? values[0].grower : '',
        farm: values.length ? values[0].farm : '',
        field: values.length ? values[0].field : ''
      }
    }
    return resultObj
  })
  // only display samples with values or that are pending; remove non-existent values
  return ret.filter(obj => typeof obj.Value === 'number' || obj.pending)
}


/**
 * Helper function sorts an array of graph objects for display based on a given sortOrder
 * For all groupings, alphabetical and by value is supported.
 * For id groupings, farm, field and sampling date are also supported
 *
 */
export const sortGraphObjects = (graphObjArr, grouping, sortOrder) => {
  const sortFunctions = {
    valueAsc: (a, b) => (parseFloat(a['Value']) || 0) < (parseFloat(b['Value']) || 0) ? -1 : 1,
    valueDesc: (a, b) => (parseFloat(a['Value']) || 0) > (parseFloat(b['Value']) || 0) ? -1 : 1,
    sampling_date: (a, b) => skewerDateSort(a.sampling_date, b.sampling_date),
    sampling_date_group: (a, b) => skewerDateSort(a.grouping, b.grouping),
    farm: (a, b) => a.farm === b.farm
      ? numericStringComparison(a.grouping, b.grouping)
      : numericStringComparison(a.farm, b.farm),
    field: (a, b) => a.field === b.field
      ? numericStringComparison(a.grouping, b.grouping)
      : numericStringComparison(a.field, b.field),
    defaultAlpha: (a, b) => numericStringComparison(a.grouping, b.grouping)
  }
  graphObjArr = graphObjArr.sort(sortFunctions[sortOrder])
  graphObjArr.forEach(obj => {
    obj['label'] = obj['grouping']
    if (grouping === 'id' && ['field', 'farm', 'sampling_date'].includes(sortOrder)) {
      obj['label'] = obj['grouping'] + '-' + obj[sortOrder]
    }
  })
  return graphObjArr
}
