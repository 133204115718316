import React, { Component, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch, withRouter, Redirect, matchPath } from 'react-router-dom'
import { getFormSyncErrors, getFormSubmitErrors, formValueSelector } from 'redux-form'

import {
  loginUser,
  resetPassword,
  getUser,
  getViewingUser
} from '../components/auth/auth-actions'

import { getAccountRequestedPermissions } from '../common/actions-reducers/permissionsActions'
import { getConstants } from '../common/actions-reducers/constants-actions'
import { getProducts } from '../common/actions-reducers/products-actions'
import { allowUnreleased } from '../index'
import addTrackingAttributes from './addTrackingAttributes'
import { StyledContainer, StyledWrapper } from './appStyles'


// constant components
import NavBar from '../components/NavBar/NavBar'
import FlashMessages from '../components/FlashMessages/FlashMessages'
import ViewOnlyBar from '../components/ViewOnly/ViewOnlyBar'
import Loader from '../common/components/Loader'
import UnreleasedVisibility from './UnreleasedVisibility'
import { PrivateRoute } from './CustomRoutes'

//Route Components
// Auth
const Login2 = lazy(() => import('../components/auth/Login'))
const Register2 = lazy(() => import('../components/auth/Register'))
const MoreDetails = lazy(() => import('../components/auth/MoreDetailsForm'))
const InviteByEmail = lazy(() => import('../components/auth/InviteByEmail'))
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'))
const FieldSetupInfo = lazy(() => import('../components/auth/FieldSetupInfo'))
// Fallback
const NotFound = lazy(() => import('../components/NotFound/NotFound'))
const AccessError = lazy(() => import('../components/NotFound/AccessError'))
// Orders
const OrderListContainer = lazy(() => import('../components/Orders/OrderListContainer'))
const OrderCreationContainer = lazy(() => import('../components/Orders/Create/OrderCreationContainer'))
const OrderDetailContainer = lazy(() => import('../components/Orders/OrderDetailContainer'))
// Maps
const FieldDetailsContainer = lazy(() => import('../components/FieldDetails/FieldDetailsContainer'))
const FarmOverviewContainer = lazy(() => import('../components/FarmOverview/FarmOverviewContainer'))
const FieldManagementContainer = lazy(() => import('../components/FieldManagement/FieldManagementContainer'))
// Analytics
const Analytics = lazy(() => import('../components/Analytics/Analytics'))
// Management
const AccountPage = lazy(() => import('../components/MyAccount/MyAccount'))
const GrowersDashboard = lazy(() => import('../components/Growers/GrowersDashboard'))
// Beta Routes
const FieldDiscovery = lazy(() => import('../components/RuleBuilder/FieldDiscovery'))

// Unreleased Routes
// None


class App extends Component {
  /**
   * <App /> is the top level URI router for page components.
   * React-Router docs: https://reacttraining.com/react-router/web/guides/philosophy
   */
  constructor(props) {
    super(props)
    this.state = {
      analyticsListener: () => { }
    }
  }

  componentDidMount() {
    //set user settings for heap analytics and update on auth changes
    this.setState({ analyticsListener: addTrackingAttributes() })
    if (this.props.isAuthenticated) {
      this.props.dispatch(getConstants())
      this.props.dispatch(getProducts())
      this.props.dispatch(getAccountRequestedPermissions())
      this.props.dispatch(getUser())
      if (sessionStorage.getItem('viewingUserId')) {
        this.props.dispatch(getViewingUser())
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.dispatch(getConstants())
      this.props.dispatch(getProducts())
      this.props.dispatch(getAccountRequestedPermissions())
    }
  }
  componentWillUnmount() {
    //unsubscribe to store
    this.state.analyticsListener()
  }

  render() {
    const {
      betaVisible,
      dispatch,
      errorStatus,
      hasResetPassErrors,
      history,
      isAuthenticated,
      isFetchingAuth,
      isViewOnly,
      location,
      unreleasedVisible,
      user
    } = this.props

    const fullScreenRoutes = [
      'field-management', 'invite',
      'field-setup', 'register',
      'login', 'complete_registration',
      'share_permission'
    ]

    const pathname = history.location.pathname
    const isFullScreen = fullScreenRoutes.includes(pathname.split('/')[1])
    const matchFieldDetails = matchPath(pathname, {
      path: `/field-details/:fieldId`,
    })
    const pathnameStyles = {
      [matchFieldDetails && matchFieldDetails.url]: {
        padding: 0,
      }
    }
    const overrideContainerStyle = pathnameStyles[pathname]
    if (!Object.keys(user).length && isAuthenticated) return <Loader />

    return (
      <div className={`app app-column-layout'}`} style={{ position: 'relative' }}>
        {sessionStorage.getItem('viewingAccountId') && <ViewOnlyBar viewedUser={user} viewOnly={isViewOnly} />}
        {allowUnreleased && <UnreleasedVisibility dispatch={dispatch} visibility={unreleasedVisible} />}
        <NavBar
          isAuthenticated={isAuthenticated}
          currentUser={user}
          unreleasedVisible={unreleasedVisible}
          betaVisible={betaVisible}
          dispatch={dispatch}
          pathname={pathname}

        />
        <StyledWrapper
          className={isFullScreen ? 'fullscreen' : ''}
          isAuthenticated={isAuthenticated}
        >
          <StyledContainer
            overrideStyle={overrideContainerStyle}
            className={`${isFullScreen ? 'fullscreen' : ''} ${isAuthenticated ? 'authenticated' : ''}`}
          >
            <FlashMessages />
            <Suspense fallback={<Loader height="60px" />}>
              <Switch>
                {/* Public Routes */}
                <Route
                  exact path='/'
                  render={() =>
                    isAuthenticated ? (
                      <Redirect
                        to={{
                          pathname: '/orders',
                          state: { from: location }
                        }}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: '/login',
                          state: { from: location }
                        }}
                      />
                    )
                  }
                />
                <Route
                  exact path='/login'
                  render={() => (
                    <Login2
                      onLoginClick={creds => dispatch(loginUser(creds))}
                      history={history}
                      dispatch={dispatch}
                      user={user}
                      isFetching={isFetchingAuth}
                    />
                  )}
                />
                <Route
                  exact path='/reset-password'
                  render={() => (
                    <ResetPassword
                      onResetClick={creds => dispatch(resetPassword(creds))}
                      hasErrors={hasResetPassErrors}
                      history={history}
                      dispatch={dispatch}
                      isFetching={isFetchingAuth}
                    />
                  )}
                />
                <Route
                  exact path='/complete_registration/:token?'
                  render={() => (
                    <MoreDetails
                      history={history}
                    />
                  )}
                />
                <Route
                  exact path='/register'
                  render={() => (
                    <Register2
                      history={history}
                    />
                  )}
                />
                {/* Private Routes */}
                <PrivateRoute
                  path='/my-account'
                  render={() => (
                    <AccountPage
                      user={user}
                      dispatch={dispatch}
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  exact path='/orders'
                  render={()=> <OrderListContainer history={history}/>}
                />
                <PrivateRoute
                  exact path='/create-order'
                  render={()=> <OrderCreationContainer history={history}/>}
                />
                <PrivateRoute
                  exact path='/orders/:orderId'
                  render={({match})=> (
                    <OrderDetailContainer
                      orderId={match.params.orderId}
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  exact path='/submit'
                  render={() => (<Redirect to={{pathname: '/create-order', state: { from: location }}}/>)}
                />
                <PrivateRoute
                  exact path='/view-samples/:submissionId?'
                  render={() => (<Redirect to={{pathname: '/orders', state: { from: location }}}/>)}
                />
                <PrivateRoute
                  exact path='/dashboard'
                  render={() => (<Redirect to={{pathname: '/orders', state: { from: location }}}/>)}
                />
                <PrivateRoute
                  exact path='/analytics/order/:orderId?'
                  render={({ match }) => (
                    <Analytics
                      location={location}
                      orderId={match.params.orderId}
                      user={user}
                      key={`analytics-${match.params.orderId}`}
                      history={history}
                    />)}
                />
                <PrivateRoute
                  path='/analytics'
                  render={() => (
                    <Analytics
                      location={location}
                      user={user}
                      key='analytics'
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  exact path='/growers'
                  render={() => (
                    <GrowersDashboard
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  path='/field-management'
                  render={() => (
                    <FieldManagementContainer
                      user={user}
                      dispatch={dispatch}
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  exact path='/invite'
                  render={() => (
                    <InviteByEmail
                      user={user}
                      history={history}
                      getUser={() => dispatch(getUser())}
                    />
                  )}
                />

                <PrivateRoute
                  exact path='/share_permission'
                  render={() => (
                    <InviteByEmail
                      user={user}
                      history={history}
                      getUser={() => dispatch(getUser())}
                      type='share'
                    />
                  )}
                />
                <PrivateRoute
                  exact path='/field-setup'
                  render={() => (
                    <FieldSetupInfo
                      user={user}
                      history={history}
                    />
                  )}
                />
                <PrivateRoute
                  path='/farm-overview/:accountId?'
                  render={() => (
                    <FarmOverviewContainer />
                  )}
                />
                <PrivateRoute
                  exact path='/field-details/:fieldId'
                  render={() => <FieldDetailsContainer />}
                />
                <PrivateRoute
                  exact path='/field-details/:fieldId/:indicatorType'
                  render={({ match }) => (
                    <FieldDetailsContainer
                      linkedIndicatorType={match.params.indicatorType}
                    />)}
                />
                <PrivateRoute
                  path='/field-discovery/:pageType'
                  render={() => <FieldDiscovery />}
                />
                <Route
                  exact path='/unauthorized'
                  render={() => (
                    <AccessError
                      statusCode={errorStatus}
                    />
                  )}
                />
                <Route render={() => <NotFound />} />
              </Switch>
            </Suspense>
          </StyledContainer>
        </StyledWrapper>
      </div>
    )
  }
}


// TODO: use selectors from reducer to unpack state to props
function mapStateToProps(state) {
  // UpdatePassword form
  const updatePassFieldValue = formValueSelector('updatePassword')(state, 'newPassword1')
  const hasUpdatePassSyncErrors = getFormSyncErrors('updatePassword')(state)
  const hasUpdatePassSubmitErrors = getFormSubmitErrors('updatePassword')(state)
  const hasUpdatePassErrors = !!(
    (!!hasUpdatePassSyncErrors && (!!hasUpdatePassSyncErrors.oldPassword || !!hasUpdatePassSyncErrors.newPassword1 || !!hasUpdatePassSyncErrors.newPassword2)) ||
    (!!hasUpdatePassSubmitErrors && (!!hasUpdatePassSubmitErrors.oldPassword || !!hasUpdatePassSubmitErrors.newPassword1 || !!hasUpdatePassSubmitErrors.newPassword2))
  )
  // ResetPassword form
  const hasResetPassSyncErrors = getFormSyncErrors('reset')(state)
  const hasResetPassSubmitErrors = getFormSubmitErrors('reset')(state)
  const hasResetPassErrors = !!(
    (!!hasResetPassSyncErrors && !!hasResetPassSyncErrors.email) ||
    (!!hasResetPassSubmitErrors && !!hasResetPassSubmitErrors.email)
  )

  const { auth } = state
  const { isAuthenticated, user, isViewOnly } = auth
  const { unreleasedVisible, errorStatus, betaVisible } = state.meta

  const isFetchingAuth = auth.isFetching

  return {
    betaVisible,
    errorStatus,
    hasResetPassErrors,
    hasUpdatePassErrors,
    isAuthenticated,
    isFetchingAuth,
    isViewOnly,
    unreleasedVisible,
    updatePassFieldValue,
    user
  }
}

App.propTypes = {
  betaVisible: PropTypes.bool,
  dispatch: PropTypes.func,
  errorStatus: PropTypes.number,
  hasResetPassErrors: PropTypes.bool,
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  isFetchingAuth: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  location: PropTypes.object,
  unreleasedVisible: PropTypes.bool,
  user: PropTypes.object
}

export default withRouter(connect(mapStateToProps)(App))
