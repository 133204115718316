import {
  Add,
  Apps,
  ArrowDropDown,
  ArrowForward,
  AssessmentOutlined,
  BlurLinear,
  Cancel,
  CheckBox,
  CheckBoxOutlineBlank,
  CheckCircle,
  CheckCircleOutlineOutlined,
  CloudUpload,
  DashboardOutlined,
  DateRange,
  DeleteForeverOutlined,
  EditOutlined,
  FileCopyOutlined,
  InfoOutlined,
  LayersOutlined,
  RoomOutlined,
  Save,
  SpaOutlined,
  SubdirectoryArrowLeft,
  TextureOutlined,
  VisibilityOutlined,
  FileCopy,
} from '@material-ui/icons'

import { ReactComponent as FieldDiscovery } from '../../common/svgs/Funnel.svg'
import { ReactComponent as PdfReport } from '../../common/svgs/PDFReport.svg'

// FMIS
export const FarmIcon = TextureOutlined
export const FieldIcon = Apps
export const GrowerIcon = SpaOutlined
export const SamplesIcon = BlurLinear
// nav
export const AnalyticsIcon = AssessmentOutlined
export const DashboardIcon = DashboardOutlined
export const FieldDiscoveryIcon = FieldDiscovery
export const MapsIcon = RoomOutlined
export const OrdersIcon = LayersOutlined
export const PdfReportIcon = PdfReport
// tools
export const AddIcon = Add
export const DropDownIcon = ArrowDropDown
export const CalendarIcon = DateRange
export const CancelIcon = Cancel
export const CheckBoxIcon = CheckBox
export const CheckBoxOutlineBlankIcon = CheckBoxOutlineBlank
export const CheckCircleIcon = CheckCircle
export const CheckCircleOutlinedIcon = CheckCircleOutlineOutlined
export const CopyIcon = FileCopyOutlined
export const DeleteIcon = DeleteForeverOutlined
export const EditIcon = EditOutlined
export const EnterKeyIcon = SubdirectoryArrowLeft
export const InfoIcon = InfoOutlined
export const SaveIcon = Save
export const CopyToAllIcon = FileCopy
export const UploadIcon = CloudUpload
export const ViewIcon = VisibilityOutlined
export const ArrowForwardIcon = ArrowForward
