import { cropsFromState } from '../actions-reducers/crop-reducer'
import { numericStringComparison } from './sortHelpers'
import {
  userCanAddSamples,
  getSampleNameAndDepthKey,
  getSampleNameSet,
  getSampleNameAndDepthKeySet } from '../../components/Orders/helpers'
import { containsAlphaNumeric } from './stringAndDateHelpers'


export const getValidatorOptions = (validatorRule) => {
  const validatorOptions = {
    depthUnitFormat: {
      options: ['inches', 'cm']
    },
    plantingStageMatch: {
      options: ['pre-planting', 'in-season', 'post-harvest']
    }
  }
  return validatorOptions[validatorRule]?.options || []
}

export const getValidatorDescription = (validatorRule) => {
  const defaultDescription = 'Value should be one of the following: '
  const validatorDescriptions = {
    typeString: {
      description: 'Value should be a string'
    },
    lengthBelowOrEqual50: {
      description: 'Value should contain less than or equal to 50 characters'
    },
    notFalsy: {
      description: 'Value should not be blank'
    },
    typeNumber: {
      description: 'Value should be a number'
    },
    validDate: {
      description: 'Value should be a date formatted as MM/DD/YYYY'
    },
    sampleIsUnique: {
      description: 'Sample name and depth should be unique'
    },
    sampleNameIsNotNew: {
      description: 'Value should not be new'
    },
    valueIsUnchanged: {
      description: 'Value can\'t be changed after order has been received in the lab'
    },
    validCrop: {
      showCropList: true
    },
    validCropOptional: {
      description: 'Value should be one of the following or blank: ',
      showCropList: true
    },
    validLat: {
      description: 'Value should be between -90 and 90'
    },
    validLong: {
      description: 'Value should be between -180 and 180'
    },
    lessThanMaxDepth: {
      description: 'Value should be less than or equal to Max Depth'
    }
  }

  const validatorOptions = getValidatorOptions(validatorRule)
  let description = validatorDescriptions[validatorRule]?.description || defaultDescription
  if (validatorDescriptions[validatorRule]?.showCropList) {
    description += `[${cropsFromState().join(', ')}]`
  } else if (validatorOptions.length) {
    description += `[${validatorOptions.join(', ')}]`
  }
  return description
}

//static validators
const
  typeString = val => typeof val === 'string',
  lengthBelowOrEqual50 = (val) => val.length <= 50,
  notFalsy = (val) => !!val,
  matchOptions = (options) => (val) => options.indexOf(val) >= 0,
  // eslint-disable-next-line eqeqeq
  typeNumber = val => typeof val === 'number' || parseFloat(val) == val,
  validDate = (val) => new Date(val) instanceof Date && !isNaN(new Date(val)),
  sampleIsUnique = (() => {
    const seenNames = new Set()
    let lastRowSeen = 0
    return (val, row, sampleName, maxDepth, sampleNameAndDepthKey) => {
      // reset on re-upload
      if (row <= lastRowSeen) {
        seenNames.clear()
      }
      lastRowSeen = row
      if (seenNames.has(sampleNameAndDepthKey)) {
        return false
      } else {
        seenNames.add(sampleNameAndDepthKey)
        return true
      }
    }
  })()

//static common normalizers
export const
  toDateFormat = (val) => {
    if (!val) return null
    if (validDate(val)) {
      return new Date(val).toISOString().split('T')[0]
    } else {
      return null
    }
  },
  plantingStageNorm = (val) => {
    let options =
    {
      'pre-planting': 'Pre-Planting',
      'in-season': 'In-Season',
      'post-harvest': 'Post-Harvest'
    }
    return options[val?.toLowerCase()] || null
  },
  // format to boolean right before saving samples so that we know if user explicitly entered a value
  includeNorm = (val) => {
    let options =
    {
      'yes': true,
      'no': false,
    }
    return options[val?.toLowerCase()] || false
  },
  toLowerCase = val => val ? val.toLowerCase() : val,
  toUpperCase = val => val ? val.toUpperCase() : val,
  toArray = val => val ? val.split(',').map(val => val.trim()) : [],
  toFloat = val => Number.isNaN(parseFloat(val)) ? null : parseFloat(val),
  toInt = val => Number.isNaN(parseInt(val)) ? null : parseInt(val),
  // add this to any field where an empty string is invalid (farm, field for example)
  emptyStringToNull = val => val === '' ? null : val

//expected columns and validation functions

export const sampleUploadColumns = {
  sample_name: {
    name: 'sample_name',
    display: 'Sample Name',
    validators: {
      typeString,
      lengthBelowOrEqual50,
      notFalsy,
      sampleIsUnique
    },
    normalizers: [emptyStringToNull],
    warnings: [
      (val, row, sampleNameAndDepthKey, existingSampleNameSet, existingSampleNameAndDepthKeySet) => {
        if (existingSampleNameSet.has(val) && !existingSampleNameAndDepthKeySet.has(sampleNameAndDepthKey)) {
          return {
            text: 'A new sample will be created for this sample name and depth. If you wish to edit the depth on a specific sample, you must do so on the TraceView Order Details page',
            value: `${sampleNameAndDepthKey.split('::').join('/')} (sample_name/min_depth/max_depth/depth_unit)`
          }
        }
      }],
      required: true
  },
  latitude: {
    name: 'latitude',
    display: 'Latitude (degrees)',
    validators: {
      typeNumber,
      validLat: val => val > -90 && val < 90,
    },
    normalizers: [toFloat],
    warnings: [],
    type: 'number',
    description: '-90 to 90'
  },
  longitude: {
    name: 'longitude',
    display: 'Longitude (degrees)',
    validators: {
      typeNumber,
      validLong: val => val > -180 && val < 180,
    },
    normalizers: [toFloat],
    warnings: [],
    type: 'number',
    description: '-180 to 180'
  },
  sample_tags: {
    name: 'sample_tags',
    display: 'Sample Tags',
    validators: {
      typeString,
    },
    normalizers: [toArray],
    warnings: [],
  },
  sampling_date: {
    name: 'sampling_date',
    display: 'Sampling Date',
    validators: {
      typeString,
      validDate,
    },
    normalizers: [toDateFormat],
    warnings: [],
    type: 'date'
  },
  min_depth: {
    name: 'min_depth',
    display: 'Min Depth',
    validators: {
      typeNumber,
      lessThanMaxDepth: (value, row, sampleName, maxDepth) => Number(value) <= Number(maxDepth)
    },
    normalizers: [toInt],
    warnings: [],
    type: 'number',
    required: true
  },
  max_depth: {
    name: 'max_depth',
    display: 'Max Depth',
    validators: {typeNumber},
    normalizers: [toInt],
    warnings: [],
    type: 'number',
    required: true
  },
  depth_unit: {
    name: 'depth_unit',
    display: 'Depth Unit',
    validators: {
      notFalsy,
      typeString
    },
    normalizers: [],
    warnings: [],
    options: getValidatorOptions('depthUnitFormat'),
    required: true
  },
  planting_stage: {
    name: 'planting_stage',
    display: 'Planting Stage',
    validators: {
      typeString,
      plantingStageMatch: val =>
        matchOptions(getValidatorOptions('plantingStageMatch'))(
          val.toLowerCase()
        ),
    },
    normalizers: [plantingStageNorm],
    warnings: [],
    options: getValidatorOptions('plantingStageMatch'),
  },
  previous_crop: {
    name: 'previous_crop',
    display: 'Previous Crop',
    validators: {
      validCropOptional: val =>
        // eslint-disable-next-line eqeqeq
        matchOptions(cropsFromState())(val.toUpperCase()) || val == '',
      typeString,
    },
    normalizers: [emptyStringToNull, toUpperCase],
    warnings: [],
  },
  current_crop: {
    name: 'current_crop',
    display: 'Current Crop',
    validators: {
      validCropOptional: val =>
        // eslint-disable-next-line eqeqeq
        matchOptions(cropsFromState())(val.toUpperCase()) || val == '',
      typeString,
    },
    normalizers: [emptyStringToNull, toUpperCase],
    warnings: [],
  },
  next_crop: {
    name: 'next_crop',
    display: 'Next Crop',
    validators: {
      validCrop: val =>
        matchOptions([...cropsFromState()])(val.toUpperCase()),
      typeString,
    },
    normalizers: [emptyStringToNull, toUpperCase],
    warnings: [],
  },
}

const getSampleUploadColumns = (order, viewingUser) => {
  if (order?.received_date) {
    sampleUploadColumns.sample_name.validators.sampleNameIsNotNew = name => userCanAddSamples(viewingUser, order.samples)
      ? true // allow viewing user with customer edit access to add new samples
      : order.samples.some(s => s.sample_name === name)
  } else {
    delete sampleUploadColumns.sample_name.validators.sampleNameIsNotNew
  }
  return sampleUploadColumns
}

//CSV parser
const parseCSVRow = (row) => {
  let cols = row.split(',')
  //look for any commas that were bounded by double quotes
  for (let i = 0; i < cols.length; i++) {
    let splitVal = cols[i]
    if (splitVal[0] === '"' && splitVal[splitVal.length - 1] !== '"') {
      let j = i + 1
      //join together array elements until we find the end
      while (j < cols.length && cols[j][cols[j].length - 1] !== '"') {
        splitVal += `,${cols[j]}`
        j++
      }
      //join the final element
      if (cols[j]) {
        splitVal += `,${cols[j]}`
      }
      //remove the elements that were joined to the first
      cols.splice(i, j - i + 1, splitVal)
    }
    //remove any double quotes in string (char not allowed for upload, at the minute.)
    cols[i] = cols[i].replace(/"/gi, '').trim()
  }
  return cols
}


// Download template CSV
export const templateName = 'tg_sample_data_entry.csv'

export const generateTemplate = (sampleData) => {
  let csvString = [Object.keys(sampleUploadColumns).join(',') + '\n']
  sampleData.sort((a, b) => numericStringComparison(a.sample_name, b.sample_name)).forEach(sample => {
    let sampleRow = Object.keys(sampleUploadColumns).map(col => {
      // booleans --> yes/no
      return typeof sample[col] === 'boolean'
        ? sample[col] === false ? 'no' : 'yes'
        // numbers stay numbers (keep zeros)
        : typeof sample[col] === 'number'
          ? sample[col]
          // arrays need wrapping to stay in the same column, nulls --> empty strings
          : sample[col]
            ? `"${sample[col]}"`
            : ''
    })
    csvString += sampleRow + '\n'
  })
  return new Blob([csvString], { type: 'text/csvcharset=utf-8' })
}

export const handleDownloadTemplate = (blob) => {
  return URL.createObjectURL(blob)
}

export const checkIE11 = (blob, filename) => {
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  }
}



/**
 * function consumes a csv file and determines if it is a valid file and if the column data is valid
 * based on a set of column spec validators. If the field is valid, it will also run the value through
 * a set of normalizer functions if provided.
 *
 * @param {array} file CSV file string
 * @param {function} callback function to call with (error, {warnings, values}) as args
 * @param {array} columnSpecs object with keys of header names and
 * @param {object} options
 *  {
 *    required[] array of required headers. if not specified,  all required.
 *
 *    row_validators[] array of functions to validate row. Passed row object and row index.
 *                     validator should return null on pass, error string on failure
 * }
 *
 */
const validateNormalizeRows = (file, callback, columnSpecs, order, columnMap, options = {}) => {
  options = {
    required: [...Object.keys(columnSpecs)],
    row_validators: [],
    ...options
  }
  let errors = []
  let warnings = []
  let splitFile = file.split('\n')
  let returnData = []
  const existingSampleNameSet = getSampleNameSet(order?.samples || [])
  const existingSampleNameAndDepthKeySet = getSampleNameAndDepthKeySet(order?.samples || [])

  //validate header rows
  let header = splitFile.shift().split(',')

  //remove trailing blank columns, stops looking at first blank column
  let maxIndex = header.indexOf('') > 0 ? header.indexOf('') : header.length
  //clean header names
  header = header.map(col => col.toLowerCase().trim())

  if (maxIndex > columnSpecs.length || maxIndex < options.required.length) {
    errors.push('CSV has incorrect number of headers')
  }
  //check all required present
  options.required.forEach((col) => {
    if (header.indexOf(col) < 0) {
      errors.push(`Required column ${col} not present`)
    }
  })
  //if either of the preconditions above are true, don't continue expensive processing
  if (errors.length > 0) {
    callback(errors)
    return
  }
  // parse each row into an array of columns
  for (let row = 0; row < splitFile.length; row++) {
    let sampleValues = parseCSVRow(splitFile[row])

    // skip empty rows
    if (sampleValues.every(value => !containsAlphaNumeric(value))) continue

    // if user mapped columns, only bring in those values
    if (Object.keys(columnMap).length) {
      sampleValues = sampleValues.map((val, valIndex) => !(columnMap[header[valIndex]]) ? '' : val)
    }

    //check if row is empty. if so, remove instead of exiting function entirely
    if (sampleValues.length === 1 && sampleValues[0] === '') {
      splitFile.splice(row, 1)
      continue
    }

    //validate all rows are same length
    if (sampleValues.length !== header.length) {
      errors.push(`Row ${row + 1} has incorrect number of columns `)
      continue
    }

    //run through additional validators
    options.row_validators.forEach(func => {
      let resp = func(sampleValues, header, row)
      resp && errors.push(resp)
    })

    const sampleValuesByProperty = header.reduce((acc, property, propertyIndex) => ({ ...acc, [property]: sampleValues[propertyIndex] }), {})
    const sampleNameAndDepthKey = getSampleNameAndDepthKey(sampleValuesByProperty)
    //for every column, check each validator in column specs
    //push error for each failing column
    sampleValues.forEach((value, colIndex) => {
      let colName = header[colIndex]
      if (colIndex >= maxIndex) return
      if (!value) {
        if (options.required.indexOf(colName) >= 0) {
          errors.push(
            `Row ${row + 1} has no value for column "${colName}". Column is required.`
          )
        }
        // before received, any column (except name) can be null
        // after received, product must retain original values
        if (!order?.received_date) return
      }
      let spec = columnSpecs[colName]
      if (!spec) {
        return
      }
      let validators = spec.validators
      for (let validatorRule in validators) {
        const maxDepth = sampleValues[header.indexOf('max_depth')]
        if (!validators[validatorRule](value, row, sampleValues[header.indexOf('sample_name')], maxDepth, sampleNameAndDepthKey)) {
          errors.push(
            `Row ${row + 1} has errors in column "${columnMap[colName] || colName
            }" ${columnMap[colName]
              ? '(mapped to column "' + colName + '")'
              : ''} -- ${getValidatorDescription(validatorRule)}. Current value: ${ validatorRule === 'sampleIsUnique'
                ? `sample_name: ${sampleValuesByProperty['sample_name']}, min_depth/max_depth/depth_unit: ${sampleValuesByProperty['min_depth']} \
                  /${sampleValuesByProperty['max_depth']}/${sampleValuesByProperty['depth_unit']}`
                : value}`
          )
        }
      }
      for (let warnIndex in spec.warnings) {
        let warning = spec.warnings[warnIndex](value, row, sampleNameAndDepthKey, existingSampleNameSet, existingSampleNameAndDepthKeySet)
        if (warning) {
          warnings.push(
            `Row ${row + 1} column ${spec.name} -- ${warning.text}. Current value: ${warning.value}`
          )
        }
      }
    })
    /* if we have no errors, create a sample object with key/values, normalize, and push to return data array
     *
     * 2 modes to handle yes/no values (include_bio and include_chem):
     *   new order - normalize yes/no to booleans upon upload
     *   existing order: delay normalizing until prior to saving so we know if the user explicitly sets these values
     */
    if (!errors.length) {
      returnData.push(
        sampleValues.reduce((memo, value, colIndex) => {
          let spec = columnSpecs[header[colIndex]]
          if (!spec || (order && !value)) return memo
          memo[spec.name] = spec.normalizers.reduce(
            (memo, func) => func(memo),
            value
          )
          return memo
        }, {})
      )
    }
  }

  //only call callback with data if no errors
  errors.length ? callback(errors) : callback(null, {
    warnings,
    values: returnData
  })
  return
}

export const createCSVProcessing = (file, callback, order, columnMap, viewingUser) => validateNormalizeRows(
  file,
  callback,
  getSampleUploadColumns(order, viewingUser),
  order,
  columnMap,
  {
    required: ['sample_name', 'min_depth', 'max_depth', 'depth_unit'],
    row_validators: [
      (row, header, index) => {
        let fieldIdx = header.indexOf('field')
        let farmIdx = header.indexOf('farm')
        if (!((row[fieldIdx] && row[farmIdx]) || (!row[fieldIdx] && !row[farmIdx]))) {
          return (
            `Row ${index + 1} must contain either both farm and field or neither farm nor field`
          )
        }
      }
    ]
  }
)
