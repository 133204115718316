import { combineReducers } from 'redux'

import samplesReducer from '../common/actions-reducers/samples-reducer'
import {cropsReducer} from '../common/actions-reducers/crop-reducer'
import { farmsReducer}  from '../common/actions-reducers/farmsReducer'
import { fieldsReducer } from '../common/actions-reducers/fieldsReducer'
import { permissionsReducer } from '../common/actions-reducers/permissionsReducer'
import { ordersReducer } from '../common/actions-reducers/orders-reducer'
import { productsReducer } from '../common/actions-reducers/products-reducer'
import { indicatorReducer } from '../common/actions-reducers/indicator-reducer'
import { tagsReducer } from '../common/actions-reducers/tags-reducer'
import { reportsReducer } from '../common/actions-reducers/reports-reducer'

const entitiesReducer = combineReducers({
  samples: samplesReducer,
  crops: cropsReducer,
  indicators: indicatorReducer,
  farms: farmsReducer,
  fields: fieldsReducer,
  permissions: permissionsReducer,
  orders: ordersReducer,
  products: productsReducer,
  tags: tagsReducer,
  reports: reportsReducer
})

export default entitiesReducer
