import styled, { css } from 'styled-components'

import { Card } from '../../common/styledComponents/StyledCard'

export const StyledAnalyticsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  font-size: 13px;
  padding: 25px 30px;
`

export const StyledNullNotify = styled.div`
  padding: 5em 20%;
  color: black;
  font-size: large;
  font-weight: 700;
  border-radius: 4px;
  border: 1px ${props => props.theme.colors.primary} solid;
  margin-bottom: 1.5em;
  text-align: center;
  background-color: #ffffff;
`

export const InlineLink = styled.a`
  color: ${props => props.useprimarycolor ? props.theme.colors.primary : props.theme.colors.black};
  text-decoration: underline !important;
  :hover{
    color: ${props => props.theme.colors.primary} !important;
    cursor:pointer;
  }
`

export const StyledInputLabel = styled.div`
  margin-bottom: 3px;
  font-size: 14px;
`

export const StyledRadioLabel = styled.label`
  height: 38px;
  width: 220px;
  background-color: #ffffff !important;
  border-color: ${props => props.theme.colors.primary} !important;
  color: ${props => props.theme.colors.primary};
  border-radius: 3px;
  font-size: 13px;
  &.active{
    background-color: ${props => props.theme.colors.primary} !important;
    border-color: transparent !important;
    color: #ffffff;
  }
  :hover{
    background-color: ${props => props.activeColor ? props.activeColor : '#2d8a87'} !important;
    border-color: ${props => props.activeColor ? props.activeColor : '#2d8a87'} !important;
    color: #ffffff;
  }
  &.disabled{
    background-color: #929292 !important;
  }
  &.focus{
    outline: none !important;
    box-shadow: none !important;
  }
`

export const StyledDatePickerContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  >div{
    min-width: 130px;
    width: calc(50% - 15px);
    div{
      white-space: nowrap;
    }
  }
`

export const StyledDatePicker = styled.input`
  align-items: center;
  border: 1px solid hsl(0,0%,80%) ;
  border-radius: 4px;
  padding: 8px 0;
  text-indent: 8px;
  width: 100%;
  height: 38px;
  font-size: 16px;
  &::-webkit-inner-spin-button{
    display:none;
  }
`

export const StyledPlaceHolderContainer = styled.div`
  border: 2px #d8d8d8 dashed;
  margin: 2em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const StyledPlaceHolderIndicators = styled(StyledPlaceHolderContainer)`
  padding: 1.75em;
  text-align: center;
  min-height: 300px;
`

export const StyledListItem = styled.button`
  font-size: 18px;
  height: 100%;
  border-radius: 0px !important;
  &.active{
    background-color: ${props => props.theme.colors.primary} !important;
    border-color: transparent !important;
    color: #ffffff;
  }
  :hover{
    background-color: #2d8a87 !important;
    color: #ffffff;
  }
  :hover .analytics-listitem-subtitle{
    color: #c7c7c5;
  }
  :focus{
    outline: none;
  }
`

export const StyledListItemSubTitle = styled.span`
  display: block;
  left: 0;
  right: 0;
  bottom: 7px;
  font-family: Lato;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #767573;
  margin: 0;
  padding: 0;
  pointer-events: none;
`

export const StyledInnerModal = styled.div`
  border-radius: 3px;
  margin: 20% auto !important;
  z-index: 6;
`

export const StyledSampleRow = styled.li`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 1em .25em;
`

export const StyledCheckBoxesWrapper = styled.div`
  margin: 0 1em;
`

export const SampleListWrapper = styled.ul`
  height: 300px;
  overflow-y: auto;
`

export const StyledSampleRowHeader = styled(StyledSampleRow)`
  font-weight: bold;
  border-bottom: 1px solid #d8d8d8;
`

export const StyledToolTip = styled.div`
  background-color: #ffffff;
  padding: 2em;
  border: 1px solid #d8d8d8;
  width: 220px;
  font-size: small important!;
  border-radius: 10px;

  h5 {
    font-size: 1rem;
    color: black;
  }
  p {
    color: #6d6e71;
    padding: 0;
    margin: 0;
  }
`
export const StyledIndicatorList = styled.div`
  overflow-y: auto;
  padding: .25px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  font-size: smaller;
  height: auto;
  max-height: 250px;
  @media screen and (min-width: 768px){
    max-height: 500px;
  }
`


export const StyledMultiDropDown = styled.div`
@media screen and (min-width: 992px){
  -webkit-flex: 1 1 15 !important;
  flex: 1 1 15% !important;
}
`

export const StyledX = styled.span`
  display: ${(props) => props.hide ? 'none' : 'inline'}
  position: absolute;
  bottom: 7px;
  right: 7px;
  font-weight: 700;
  color: #d8d8d8
  cursor: pointer;
  :hover {
    color: #747578
  }
`

export const StyledDataOutputContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`

export const SplitDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
`

export const DetailsText = styled.p`
  font-family: Lato;
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  font-size: ${props => props.size || '14px'};
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${props => props.color || '#767573'};
  min-width: 30%;

  ${props => {
  if (props.ellipsis) {
    return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: unset;
      `
  }
}}
`

export const StyledReportContentCard = styled(Card)`
  justify-content: space-between;
  padding: 0;
  height: auto;
  border-right: 1px solid #E0E0E0;
  border-radius: 6px 0 0 6px;
`

