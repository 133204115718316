import { createSelector } from 'reselect'

import { appStore } from '../../../index'
import {
  ORDER_SAMPLE_POST_SUCCESS,
} from '../../../common/actions-reducers/orders-actions'
import { createFarm } from '../../../common/actions-reducers/farmActions'
import { createField } from '../../../common/actions-reducers/fieldActions'
import { postOrder, postOrderSamples } from '../../../common/actions-reducers/orders-actions'


//////////////////
// ACTIONS
//////////////////
const RESET = 'RESET'
const SET_STEP = 'SET_STEP'
const SET_SELECTED_GROWER_ACCT = 'SET_SELECTED_GROWER_ACCT'
const SET_SELECTED_FARM = 'SET_SELECTED_FARM'
const SET_SELECTED_FIELD = 'SET_SELECTED_FIELD'
const SET_SAMPLE_DATA = 'SET_SAMPLE_DATA'
const IS_CREATING_ORDER = 'IS_CREATING_ORDER'
const ORDER_CREATION_SUCCESS = 'ORDER_CREATION_SUCCESS'
const ORDER_CREATION_FAILURE = 'ORDER_CREATION_FAILURE'


export function reset() {
  return { type: RESET }
}

export function setStep(step) {
  return {
    type: SET_STEP,
    step
  }
}

export function setSelectedGrowerAccount(selectedGrowerAcct) {
  return {
    type: SET_SELECTED_GROWER_ACCT,
    selectedGrowerAcct
  }
}

export function setSelectedFarm(selectedFarm) {
  return {
    type: SET_SELECTED_FARM,
    selectedFarm
  }
}

export function setSelectedField(selectedField) {
  return {
    type: SET_SELECTED_FIELD,
    selectedField
  }
}

export function setSampleData(sampleData) {
  return {
    type: SET_SAMPLE_DATA,
    sampleData
  }
}

const isCreatingOrder = () => ({
  type: IS_CREATING_ORDER
})

const orderCreationSuccess = () => ({
  type: ORDER_CREATION_SUCCESS
})

const orderCreationFailure = (error) => ({
  type: ORDER_CREATION_FAILURE,
  error
})

export const createOrder = () => {
  const { createOrderState, auth } = appStore.getState()
  const { selectedGrowerAcct, sampleData, selectedFarm, selectedField } = createOrderState

  let orderId
  return dispatch => {
    dispatch(isCreatingOrder())
    return new Promise(resolve => {
      if (!selectedFarm?.id) {
        resolve(dispatch(createFarm(selectedFarm.name, selectedGrowerAcct)))
      } else {
        resolve()
      }
    })
      .then(response => {
        if (!selectedField?.id) {
          return dispatch(createField({
            account_id: selectedGrowerAcct,
            farm_id: response?.payload?.id || selectedFarm.id,
            name: selectedField?.name
          }))
        }
      })
      .then(response => dispatch(postOrder({
        ordering_account_id: auth.user.account_id,
        field_id: response?.id || selectedField.id
      })))
      .then(response => {
        orderId = response.id
        if (sampleData.length) {
          return dispatch(postOrderSamples(response.id, { samples: sampleData }))
        }
      })
      .then(() => {
        dispatch(orderCreationSuccess())
        return Promise.resolve({ orderId })
      })
      .catch(error => {
        dispatch(orderCreationFailure(error))
        return Promise.reject()
      })
  }
}

//////////////////
// REDUCER
//////////////////
const initialState = {
  step: 1,
  selectedGrowerAcct: null,
  selectedFarm: null,
  selectedField: null,
  sampleData: [],
  isCreatingOrder: false,
  serverError: null
}

export const createOrderReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case RESET: {
      return { ...initialState }
    }
    case SET_STEP: {
      return { ...state, step: action.step }
    }
    case SET_SELECTED_GROWER_ACCT: {
      return {
        ...state,
        selectedGrowerAcct: action.selectedGrowerAcct,
        selectedFarm: null,
        selectedField: null
      }
    }
    case SET_SELECTED_FARM: {
      return { ...state, selectedFarm: action.selectedFarm }
    }
    case SET_SELECTED_FIELD: {
      return { ...state, selectedField: action.selectedField }
    }
    case SET_SAMPLE_DATA: {
      return { ...state, sampleData: action.sampleData }
    }
    case ORDER_SAMPLE_POST_SUCCESS: {
      return { ...initialState }
    }
    case ORDER_CREATION_SUCCESS: {
      return { ...initialState }
    }
    case ORDER_CREATION_FAILURE: {
      return { ...state, isCreatingOrder: false, serverError: action.error }
    }
    case IS_CREATING_ORDER: {
      return { ...state, isCreatingOrder: true, serverError: null }
    }
    default: {
      return state
    }
  }
}

//////////////////
// SELECTORS
//////////////////
const getSelectedGrowerAcct = state => state.createOrderState.selectedGrowerAcct
const getSelectedFarm = state => state.createOrderState.selectedFarm
const getSelectedField = state => state.createOrderState.selectedField
const getFarms = state => state.entities.farms.farmEntities
const getFields = state => state.entities.fields.fieldEntities
const filterFarmFieldOptions = (growerAcctId, selectedFarm, selectedField, farms, fields) => {
  let farmsArray = Object.values(farms[growerAcctId] || {})
  let fieldsArray = Object.values(fields[growerAcctId] || {}).filter(field => field.farm_id === selectedFarm?.id)
  // if the selectedFarm/Field has no ID, it will be created and should be added to the select lists
  return {
    farms: (!!selectedFarm && !selectedFarm?.id) ? farmsArray.concat(selectedFarm) : farmsArray,
    fields: (!!selectedField && !selectedField?.id) ? fieldsArray.concat(selectedField) : fieldsArray
  }
}
export const getFarmFieldSelectOptions = createSelector(
  getSelectedGrowerAcct,
  getSelectedFarm,
  getSelectedField,
  getFarms,
  getFields,
  filterFarmFieldOptions,
)

export const getOrderEntities = (order=null) => {
  // There are two modes that need to return an equivilent data structure: during order creation and after
  // during order creation, return selected entities from create-actions-reducer
  // after order creation, return order entities
  let state = appStore.getState()
  let user = state.auth.user
  let createOrderState = state.createOrderState
  let permissionedAccounts = state.entities.permissions.permissionedAccounts
  let selectedGrowerAcct = order ? order.grower_account_id : createOrderState.selectedGrowerAcct
  // grower is either the user or a permissioned account
  let grower = selectedGrowerAcct === user.account_id ? user : permissionedAccounts.byAccount?.[selectedGrowerAcct] || null
  let farm = order ? state.entities.farms.farmEntities?.[order.grower_account_id]?.[order.farm_id] : createOrderState.selectedFarm || null
  let field = order ? state.entities.fields.fieldEntities?.[order.grower_account_id]?.[order.field_id] : createOrderState.selectedField || null
  let orderSamples = order ? order.samples : createOrderState.sampleData || []
  return { grower, farm, field, orderSamples }
}
