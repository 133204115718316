import {
  deleteAccountApiHelper,
  deleteBaseApiHelper,
  getAccountId,
  getBaseApiHelper,
  patchBaseApiHelper,
  postAccountApiHelper,
  postBaseApiHelper
} from '../utils/apiHelpers'
import { addFlashMessage } from '../../components/FlashMessages/messages-actions'
export const GET_PERMISSIONED_ACCOUNT_PERMISSIONS_REQUEST = 'GET_PERMISSIONED_ACCOUNT_PERMISSIONS_REQUEST'
export const GET_PERMISSIONED_ACCOUNT_PERMISSIONS_FAILURE = 'GET_PERMISSIONED_ACCOUNT_PERMISSIONS_FAILURE'
export const GET_PERMISSIONED_ACCOUNT_PERMISSIONS_SUCCESS = 'GET_PERMISSIONED_ACCOUNT_PERMISSIONS_SUCCESS'

export const GET_OWNED_ACCOUNT_PERMISSIONS_REQUEST = 'GET_OWNED_ACCOUNT_PERMISSIONS_REQUEST'
export const GET_OWNED_ACCOUNT_PERMISSIONS_FAILURE = 'GET_OWNED_ACCOUNT_PERMISSIONS_FAILURE'
export const GET_OWNED_ACCOUNT_PERMISSIONS_SUCCESS = 'GET_OWNED_ACCOUNT_PERMISSIONS_SUCCESS'

export const DELETE_ACCOUNT_PERMISSIONS_REQUEST = 'DELETE_ACCOUNT_PERMISSIONS_REQUEST'
export const DELETE_ACCOUNT_PERMISSIONS_FAILURE = 'DELETE_ACCOUNT_PERMISSIONS_FAILURE'
export const DELETE_ACCOUNT_PERMISSIONS_SUCCESS = 'DELETE_ACCOUNT_PERMISSIONS_SUCCESS'

export const UPDATE_SINGLE_ACCOUNT_PERMISSIONS_REQUEST = 'UPDATE_SINGLE_ACCOUNT_PERMISSIONS_REQUEST'
export const UPDATE_SINGLE_ACCOUNT_PERMISSIONS_FAILURE = 'UPDATE_SINGLE_ACCOUNT_PERMISSIONS_FAILURE'
export const UPDATE_SINGLE_ACCOUNT_PERMISSIONS_SUCCESS = 'UPDATE_SINGLE_ACCOUNT_PERMISSIONS_SUCCESS'

export const POST_ACCOUNT_PERMISSIONS_REQUEST = 'POST_ACCOUNT_PERMISSIONS_REQUEST'
export const POST_ACCOUNT_PERMISSIONS_SUCCESS = 'POST_ACCOUNT_PERMISSIONS_SUCCESS'
export const POST_ACCOUNT_PERMISSIONS_FAILURE= 'POST_ACCOUNT_PERMISSIONS_FAILURE'

export const ADD_GROWER_REQUEST = 'ADD_GROWER_REQUEST'
export const ADD_GROWER_SUCCESS = 'ADD_GROWER_SUCCESS'
export const ADD_GROWER_FAILURE = 'ADD_GROWER_FAILURE'

export const DELETE_GROWER_REQUEST = 'DELETE_GROWER_REQUEST'
export const DELETE_GROWER_SUCCESS = 'DELETE_GROWER_SUCCESS'
export const DELETE_GROWER_FAILURE = 'DELETE_GROWER_FAILURE'

/**
Get permissions
**/
const getAccountRequestedPermissionsRequest = () => ({
  type: GET_PERMISSIONED_ACCOUNT_PERMISSIONS_REQUEST,
  isFetching: true
})

const getAccountRequestedPermissionsSuccess = payload => ({
  type: GET_PERMISSIONED_ACCOUNT_PERMISSIONS_SUCCESS,
  payload
})

const getAccountRequestedPermissionsFailure = payloadError => ({
  type: GET_PERMISSIONED_ACCOUNT_PERMISSIONS_FAILURE,
  error: payloadError
})

const getAccountOwnedPermissionsRequest = () => ({
  type: GET_OWNED_ACCOUNT_PERMISSIONS_REQUEST,
  isFetching: true
})

const getAccountOwnedPermissionsSuccess = payload => ({
  type: GET_OWNED_ACCOUNT_PERMISSIONS_SUCCESS,
  payload
})

const getAccountOwnedPermissionsFailure = payloadError => ({
  type: GET_OWNED_ACCOUNT_PERMISSIONS_FAILURE,
  error: payloadError
})

export const getAccountRequestedPermissions = (accountId) => {
  if (!accountId){
    accountId = getAccountId()
  }
  return dispatch => {
    dispatch(getAccountRequestedPermissionsRequest())
    return getBaseApiHelper(`/account_permissions?permissioned=${accountId}`)
      .then(response => {
        return dispatch(getAccountRequestedPermissionsSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(getAccountRequestedPermissionsFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

export const getAccountOwnedPermissions = (accountId) => {
  if (!accountId){
    accountId = getAccountId()
  }
  return dispatch => {
    dispatch(getAccountOwnedPermissionsRequest())
    return getBaseApiHelper(`/account_permissions?owner=${accountId}`)
      .then(response => {
        return dispatch(getAccountOwnedPermissionsSuccess(response.data))
      })
      .catch(rejected => {
        dispatch(getAccountOwnedPermissionsFailure(rejected.response))
        return Promise.reject({ error: rejected.response })
      })
  }
}

/**
delete permissions
**/
const deleteAccountPermissionsRequest = (permissionType) => ({
  type: DELETE_ACCOUNT_PERMISSIONS_REQUEST,
  isFetching: true,
  permissionType
})

const deleteAccountPermissionsSuccess = (permissionId, permissionType) => ({
  type: DELETE_ACCOUNT_PERMISSIONS_SUCCESS,
  permissionId,
  permissionType
})

const deleteAccountPermissionsFailure = (payloadError, permissionType) => ({
  type: DELETE_ACCOUNT_PERMISSIONS_FAILURE,
  error: payloadError,
  permissionType
})

export const deletePermission = (permissionId, type) => {
  return dispatch => {
    dispatch(deleteAccountPermissionsRequest(type))
    return deleteBaseApiHelper(`/account_permissions/${permissionId}`)
      .then(response => {
        dispatch(deleteAccountPermissionsSuccess(permissionId, type))
        return response
      })
      .catch(rejected => {
        dispatch(deleteAccountPermissionsFailure(rejected.response, type))
        const deleteType = type === 'ownedAccounts' ? 'deny' : 'cancel'
        dispatch(addFlashMessage({
          type: 'danger',
          title: `Unable to ${deleteType} permission, try again later`,
          text: 'Something went wrong.'
        }))
        return Promise.reject({ error: rejected.response })
      })
  }
}

/**
patch permissions
**/
const patchAccountPermissionsRequest = (permissionType) => ({
  type: UPDATE_SINGLE_ACCOUNT_PERMISSIONS_REQUEST,
  isFetching: true,
  permissionType
})

const patchAccountPermissionsSuccess = (permissionId, permissionType, data)=> ({
  type: UPDATE_SINGLE_ACCOUNT_PERMISSIONS_SUCCESS,
  permissionType,
  data
})

const patchAccountPermissionsFailure = (payloadError, permissionType) => ({
  type: UPDATE_SINGLE_ACCOUNT_PERMISSIONS_FAILURE,
  error: payloadError,
  permissionType
})


export const patchPermission = (permissionId, type, data) => {
  return dispatch => {
    dispatch(patchAccountPermissionsRequest(type))
    return patchBaseApiHelper(`/account_permissions/${permissionId}`, data)
      .then(response => {
        return dispatch(patchAccountPermissionsSuccess(permissionId, type, response.data))
      })
      .catch(rejected => {
        dispatch(patchAccountPermissionsFailure(rejected.response, type))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Unable to update permission, try again later',
          text: 'Something went wrong.'
        }))
        return Promise.reject({ error: rejected.response })
      })
  }
}

export const confirmPermission = (permissionId, type) => {
  return dispatch => {
    dispatch(patchAccountPermissionsRequest(type))
    return patchBaseApiHelper(`/account_permissions/confirm/${permissionId}`, {})
      .then(response => {
        return dispatch(patchAccountPermissionsSuccess(permissionId, type, response.data))
      })
      .catch(rejected => {
        dispatch(patchAccountPermissionsFailure(rejected.response, type))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Unable to update permission, try again later',
          text: 'Something went wrong.'
        }))
        return Promise.reject({ error: rejected.response })
      })
  }
}


/**
create permissions
**/
export const postAccountPermissionsSuccess = (payload, permissionType) => ({
  type: POST_ACCOUNT_PERMISSIONS_SUCCESS,
  payload,
  permissionType
})

export const postAccountPermissionsRequest = (permissionType) => ({
  type: POST_ACCOUNT_PERMISSIONS_REQUEST,
  permissionType
})

export const postAccountPermissionsFailure = (err, permissionType) => ({
  type: POST_ACCOUNT_PERMISSIONS_FAILURE,
  permissionType,
  error: err
})

export function inviteUser(invitedUser, type='invite') {
  const accountId = getAccountId()
  let url = `/account_permissions/${accountId}/invite`
  if (type === 'share') {
    url = `/account_permissions/${accountId}/share`
  }
  const permissionType = type === 'invite' ? 'permissionedAccounts' : 'ownedAccounts'

  return dispatch => {
    postAccountPermissionsRequest(permissionType)
    return postBaseApiHelper( url, invitedUser )
      .then(response => {
        //TODO re-do invite actions -- they are never used in state
        dispatch(postAccountPermissionsSuccess(response.data.permission, permissionType))
        return response
      }).catch(error => {
        const { status, data } = error.response
        dispatch(postAccountPermissionsFailure(error, permissionType))
        dispatch(addFlashMessage({
          type: 'danger',
          title: 'Account Sharing Error:',
          text: status !== 500 || status !== 400 ? data.message : 'Something went wrong, try again later'
        }))
        return Promise.reject(error)
      })
  }
}

/**
Add a grower / create a headless account (will also create and return a permission)
**/
const addGrowerRequest = () => ({type: ADD_GROWER_REQUEST})
const addGrowerSuccess = permission => ({type: ADD_GROWER_SUCCESS, permission})
const addGrowerFailure = error => ({type: ADD_GROWER_FAILURE, error})

export const addGrower = name => {
  return dispatch => {
    dispatch(addGrowerRequest())
    return postAccountApiHelper('/growers', {name})
      .then(response => dispatch(addGrowerSuccess(response.data)))
      .catch(error => dispatch(addGrowerFailure(error)))
  }
}

/**
Delete a grower (will also delete its permission). Only allowed if no users/farms on grower account.
**/
const deleteGrowerRequest = () => ({type: DELETE_GROWER_REQUEST})
const deleteGrowerSuccess = permissionId => ({type: DELETE_GROWER_SUCCESS, permissionId})
const deleteGrowerFailure = error => ({type: DELETE_GROWER_FAILURE, error})

export const deleteGrower = growerAcctId => {
  return dispatch => {
    dispatch(deleteGrowerRequest())
    return deleteAccountApiHelper(`/growers/${growerAcctId}`)
      .then(response => dispatch(deleteGrowerSuccess(response.data)))
      .catch(error => dispatch(deleteGrowerFailure(error)))
  }
}
