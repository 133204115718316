/**
 * Function to sort and handle numeric edge cases
 * eg. 12_A will be sorted after 3_A instead of before
 *
 * @param {string} a - strings to sort with natural numbering sorting
 * @param {string} b - strings to sort with natural numbering sorting
 *
 * @returns {number} - Return 1 if a > b and -1 if a < b
 */

export const numericStringComparison = (a = '', b = '') => {
  if (a === b) return 0
  // split on either all digits or all non digit
  const firstSplittedItem = (a.match(/([\d]+|[\D]+)/g) || []).map(el => el.trim())
  const secondSplittedItem = (b.match(/([\d]+|[\D]+)/g) || []).map(el => el.trim())

  for (let x = 0; x < firstSplittedItem.length; x++) {
    const firstItem = firstSplittedItem[x]
    const secondItem = secondSplittedItem[x]
    if (firstItem !== secondItem) {
      // check if two items are numbers
      if (Number(firstItem) && Number(secondItem)) {
        return Number(firstItem) > Number(secondItem) ? 1 : -1
      } else {
        if (firstItem && secondItem) {
          return firstItem.toLowerCase() > secondItem.toLowerCase() ? 1 : -1
        }
        else {
          if (firstItem === secondItem) return 0
          return firstItem ? 1 : -1
        }
      }
    }
  }
  return firstSplittedItem.length > secondSplittedItem.length ? 1 : -1
}

/**
 * Function for sorts for year-first skewer-case dates
 *
 * @param {string} a,b  - year-first skewer-case dates ('YYYY-MM-DD')
 */
export const skewerDateSort = (a, b) => {
  let aDate = new Date(...(a.split('-')))
  let bDate = new Date(...(b.split('-')))
  if (+aDate === +bDate) {
    return 0
  }
  return (aDate < bDate) ? -1 : 1
}


/**
 * Sorts a set of indicators
 * Sort order takes the following precedence if the keys exist on the indicator:
 * 1. disease
 * 2. sort_order
 * 3. name
 *
 */

export const indicatorSort = (a, b) => {
  if (a.disease && b.disease) {
    return numericStringComparison(a.disease, b.disease)
  } else if ('sort_order' in a && 'sort_order' in b) {
    return (
      a.sort_order > b.sort_order ? 1 :
        a.sort_order < b.sort_order ? -1 : 0
    )
  } else {
    return numericStringComparison(a.name, b.name)
  }
}

/**
 * Sort an array of objects by a given selector function, using either native sort or numeric string comparison
 * @param {array} array - array of objects
 * @param {function} selector - function to select value key to sort on. If object doesn't have key, pushed to end of array
 * @param {function} sort - sort function to use, defualt of numeric string comparison
 *
 * @returns {array} - Sorted array

*/
export const sortBy = (array, selector, sort = numericStringComparison) => {
  return array.sort((a, b) => {
    let aVal, bVal
    try {
      aVal = selector(a)
    } catch (err) {
      aVal = undefined
    }

    try {
      bVal = selector(b)
    } catch (err) {
      bVal = undefined
    }
    return sort(aVal, bVal)
  })
}

export const groupBy = (array, selector, oneToMany = true) => array.reduce((acc, item) =>
  ({ ...acc, [selector(item)]: oneToMany ? (acc[selector(item)] || []).concat(item) : item }), {})

/**
Table sort functions
**/
export function descendingComparator(a, b, orderBy) {
  if (orderBy === 'receivedDate') {
    return (b[orderBy] !== null) - (a[orderBy] !== null) || a[orderBy] - b[orderBy]
  }

  if (typeof(a) === 'string' && typeof(b) === 'string') {
    return b[orderBy]?.localeCompare(a[orderBy], undefined, {numeric: true, sensitivity: 'base'}) || 0
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
  if (!array) { return }
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
