import { sampleUploadColumns, getValidatorDescription } from '../../common/utils/CSVHelpers'
import { getDaysDiff, arrayToString } from '../../common/utils/stringAndDateHelpers'
import { groupBy } from '../../common/utils/sortHelpers'

/*
 * Constants
 */
// internal users only with customer edit access
const numDaysToEditOrAddSamplesAfterReceived = 60

export const updateTestPackageReminder = 'Update sample-specific test packages on the TraceView Order Details page.'

/*
 * add additional sample in sample table based on last sample
 * sample.rawData contains normalized values (ex: true instead of "yes")
 */
export const copySample = (sample,
  properties=[
    'sampling_date',
    'previous_crop',
    'current_crop',
    'next_crop',
    'sample_tags',
    'planting_stage',
    'depth',
    'min_depth',
    'max_depth',
    'depth_unit',
    'product_id'
  ]) => (
  properties.reduce((acc, property) => {
    if (sample && (sample[property] || typeof sample[property] === 'number')) {
      acc[property] = sample[property]
      acc.rawData[property] = sample.rawData[property]
    }
    return acc
  }, {rawData: {}})
)

/*
 * remove properties that aren't accepted for
 *   POST in orderSample schema
 *   PATCH in sample schemas
 * format lat/lng to 6 decimal places
 * convert inclusion of bio, chem to boolean
 */
export const cleanSample = (sample,
  properties=[
    'sample_name',
    'latitude',
    'longitude',
    'include_bio',
    'include_chem',
    'sample_tags',
    'sampling_date',
    'min_depth',
    'max_depth',
    'depth_unit',
    'planting_stage',
    'previous_crop',
    'current_crop',
    'next_crop',
    'product_id',
    // properties that other processes are dependent on
    'id',
  ]) => {
  return properties.reduce((cleanedSample, property) => {
    if (property in sample) {
      if (['latitude', 'longitude'].includes(property)) {
        if (sample[property]) {
          cleanedSample[property] = Number(sample[property]).toFixed(6)
        }
      } else if (sample[property] === '') {
        cleanedSample[property] = null
      } else {
        cleanedSample[property] = sample[property]
      }
    }
    return cleanedSample
  }, {})
}

export const getSampleNameAndDepthKey = sample => `${sample.sample_name}::${sample.min_depth}::${sample.max_depth}::${sample.depth_unit}`

export const getSampleNameSet = samples => samples.reduce((acc, cur) => acc.add(cur.sample_name), new Set())

export const getSampleNameAndDepthKeySet = samples => samples.reduce((acc, cur) => acc.add(getSampleNameAndDepthKey(cur)), new Set())

export const checkSampleForErrors = (sample={}, sampleData=[]) => {
  const errors = {}
  void ['sample_name', 'depth_unit'].forEach(property => {
    if (!sampleUploadColumns[property].validators.notFalsy(sample[property])) {
      errors[property] = getValidatorDescription('notFalsy')
    }
  })
  void ['min_depth', 'max_depth'].forEach(property => {
    if (!sampleUploadColumns[property].validators.typeNumber(sample[property])) {
      errors[property] = getValidatorDescription('typeNumber')
    }
  })
  if (sampleData.some(existingSample => getSampleNameAndDepthKey(existingSample) === getSampleNameAndDepthKey(sample))) {
    ['sample_name', 'min_depth', 'max_depth', 'depth_unit'].forEach(property => errors[property] = getValidatorDescription('sampleIsUnique'))
  }
  if (sample.latitude && !sampleUploadColumns.latitude.validators.validLat(sample.latitude)) {
    errors.latitude = getValidatorDescription('validLat')
  }
  if (sample.longitude && !sampleUploadColumns.longitude.validators.validLong(sample.longitude)) {
    errors.longitude = getValidatorDescription('validLong')
  }
  if (sample.min_depth && !sampleUploadColumns.min_depth.validators.lessThanMaxDepth(sample.min_depth, null, null, sample.max_depth)) {
    errors.min_depth = getValidatorDescription('lessThanMaxDepth')
  }
  return errors
}

export const sampleIsMissingDataCol = (sample, colName) => {
  if (!sample[colName]) {
    // optional (TODO: this needs a design that isn't hard coding duplicate information from backend)
    if (['sample_tags', 'next_crop', 'planting_stage'].includes(colName)) return false

    // either previous or current crop are required
    if (colName === 'previous_crop' && sample['current_crop']) return false
    if (colName === 'current_crop' && sample['previous_crop']) return false

    // zeros are valid values
    if (typeof sample[colName] === 'number' && sample[colName] === 0) return false

    return true
  }
}

// zeros are valid numeric values
export const validateValue = val => typeof val === 'number' ? val : val || ''

/**
 * @param {object} viewingUser
 * @param {object} sample
 * @param {string} sampleProperty
 * @returns {boolean} boolean
 */
export const userCanEditSampleProperty = (viewingUser, sample, sampleProperty) => {
  // any non-product property can be edited
  if (!['product_id'].includes(sampleProperty)) return true
  // user without customer edit access can NOT edit product properties after sample is received
  // user with customer edit access can NOT edit product properties after n days from sample receipt
  if ((!viewingUser?.flags?.['allow-edit'] && sample.received_date) ||
    getDaysDiff(sample.received_date) > numDaysToEditOrAddSamplesAfterReceived) return false
  // product can NOT be edited by user without customer edit access after bio OR chem results return
  if (sampleProperty === 'product_id') return (!sample.processed_date && !sample.chem_processed_date) || viewingUser?.flags?.['allow-edit']
}

export const userCanAddSamples = (viewingUser, samples) => {
  // user without customer edit access can NOT add samples after EVERY sample is received
  // user with customer edit access can NOT add samples after n days from EARLIEST sample receipt date
  if ((!viewingUser?.flags?.['allow-edit'] && !!samples.length && samples.every(s => s.received_date)) ||
    samples.some(sample => getDaysDiff(sample.received_date) > numDaysToEditOrAddSamplesAfterReceived)) return false
  return true
}

export const getProductDescription = (indicatorKeys, allByKey) => {
  const indicators = indicatorKeys.filter(k => allByKey[k]).map(k => allByKey[k])
  const indicatorsByType = groupBy(indicators, el => el.indicator_type)
  const description = {}
  for (const type in indicatorsByType) {
    if (type === 'chemistry') {
      description[type] = arrayToString(indicatorsByType[type].map(i => i.name))
    } else if (type === 'pathogen') {
      description.biology = description.biology || []
      description.biology.push('Pathogens')
    } else if (type === 'shi') {
      description.biology = description.biology || []
      description.biology.push('Nutrient Cycling Indicators')
    }
  }
  if (description.biology) {
    description.biology = arrayToString(description.biology)
  }
  return description
}

export const orderDetailColumns = Object.values(sampleUploadColumns).reduce((acc, col) => {
  acc[col.name] = col
  if (col.name === 'longitude') {
    acc['product_id'] = {
      name: 'product_id',
      display: 'Test Package',
      validators: {},
      normalizers: [],
      warnings: [],
    }
  }
  return acc
}, {})
